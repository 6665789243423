import Robusta from "../assets/images/products/Robusta.jpg";
import Robusta2 from "../assets/images/products/Robusta-2.png";
import Robusta3 from "../assets/images/products/Robusta-3.png";
import Espresso from "../assets/images/products/Espresso.jpg";
import ComingSoon from "../assets/images/products/coming-soon.jpg";
import CoffeeFilterPaper from "../assets/images/products/CoffeeFilterPaper.jpg";
import MilkFrothersCoffee from "../assets/images/products/milk-frothers-coffee.jpg";
import GreenCoffeeBeans from "../assets/images/products/GreenCoffeeBeans.jpg";
import GreenCoffeeBeans2 from "../assets/images/products/GreenCoffeeBeans-2.png";
import GreenCoffeeBeans3 from "../assets/images/products/GreenCoffeeBeans-3.png";
import GreenCoffeeBeans4 from "../assets/images/products/GreenCoffeeBeans-4.png";
import GreenCoffeeBeans5 from "../assets/images/products/GreenCoffeeBeans-5.png";
import ArabicaCoffee from "../assets/images/products/arabica-coffee.jpg";
import ArabicaCoffee2 from "../assets/images/products/arabica-coffee-2.png";
import ArabicaCoffee3 from "../assets/images/products/arabica-coffee-3.png";
import ArabicaCoffee4 from "../assets/images/products/arabica-coffee-4.png";
import ArabicaCoffee5 from "../assets/images/products/arabica-coffee-5.png";
import Carafes from "../assets/images/products/carafes.jpg";
import CoffeeGrinderBeans from "../assets/images/products/coffee-grinder-beans.jpg";
import ElectricGrinderCoffee from "../assets/images/products/gear3.jpeg";
import GroundCoffee from "../assets/images/products/ground-coffee.jpg";
import InstantCoffee from "../assets/images/products/instant-coffee.jpg";
import ManualCoffeeGrinders from "../assets/images/products/gear2.jpeg";
import SouthIndianFilterCoffee2 from "../assets/images/products/south-Indian-Filter-Coffee.jpg";
import SouthIndianFilterCoffee from "../assets/images/products/south-Indian-Filter-Coffee.png";
import Stencils from "../assets/images/products/gear4.jpeg";
import Kaapi from "../assets/images/products/kaapiCoffee.png";
import Kaapi2 from "../assets/images/products/kaapiCoffee-2.png";
import Kaapi3 from "../assets/images/products/kaapiCoffee-3.png";
import Kaapi4 from "../assets/images/products/kaapiCoffee-4.png";
import Jolt from "../assets/images/products/joltCoffee.png";
import Jolt2 from "../assets/images/products/joltCoffee-2.png";
import Jolt3 from "../assets/images/products/joltCoffee-3.png";
import Jolt4 from "../assets/images/products/joltCoffee-4.png";
import Stovetop from "../assets/images/products/Stovetop.png";
import CoffeeWarmer from "../assets/images/products/coffeewarmer.png";
import Vintage from "../assets/images/products/vintageCoffee.png";
import Vintage2 from "../assets/images/products/vintageCoffee-2.png";

export const productsData = [
  {
    id: "04",
    gram: "300 gm",
    name: "Instant Coffee",
    description: "InstantCoffee",
    images: [InstantCoffee],
    price: 0,
    category: "coffee",
  },

  {
    id: "54",
    gram: "300 gm",
    name: "VINTAGE",
    description:
      "VINTAGE is our signature freeze-dried coffee, a medium roast blend, which is mild, aromatic, rich and smooth. <br/> Experience this rich and aromatic flavor crafted with the world's finest Arabica coffee beans. <br/><br/>VINTAGE is crafted using 100% pure Arabica coffee beans known for their smooth, mellow, and rich flavor profile. <br/><br/>100g",
    images: [Vintage, Vintage2],
    price: 599,
    category: "instantCoffee",
  },
  {
    id: "56",
    gram: "300 gm",
    name: "JOLT",
    description:
      "Jolt is our signature blend of Instant Coffee and Chicory Mixture to elevate your daily coffee routine. With a harmonious blend of premium coffee and high-quality chicory, JOLT promises a unique and satisfying taste that's perfect for any occasion. It's a true indulgence for coffee lovers. <br/> The chicory in our mixture complements the coffee perfectly, creating a smoother and less bitter taste. You'll love the balanced finish with every sip. <br/><br/> 100g",
    images: [Jolt, Jolt2, Jolt3, Jolt4],
    price: 325,
    category: "instantCoffee",
  },
  {
    id: "57",
    gram: "300 gm",
    name: "KAAPI ",
    description:
      "KAAPI is our signature South Indian filter coffee powder. A carefully balanced blend of high-quality Arabica and Robusta coffee beans, expertly roasted and mixed with chicory to create a harmonious flavor profile. Experience the Rich Flavor of South Indian Filter Coffee. KAAPI is renowned for its unique brewing method and the use of chicory to enhance the aroma and texture. Our KAAPI brings this tradition to your cup. It is easy to use with a traditional South Indian coffee filter or even a drip machine. The moment you open the seal, you'll be greeted by the tantalizing aroma of freshly ground coffee and chicory. It's a sensory experience that sets the stage for a delightful coffee journey.",
    images: [Kaapi, Kaapi2, Kaapi3, Kaapi4, SouthIndianFilterCoffee2],
    price: 299,
    category: "groundCoffee",
  },
  {
    id: "05",
    gram: "300 gm",
    name: "Ground Coffee",
    description: "Ground Coffee",
    images: [GroundCoffee],
    price: 0,
    category: "coffee",
  },
  {
    id: "06",
    gram: "300 gm",
    name: "South Indian Coffee Filter",
    description:
      "Bring home the rich heritage of South Indian coffee brewing with our Traditional South Indian Coffee Filter. Designed to perfectly brew the famous filter coffee (also known as KAAPI), this elegant yet practical coffee filter promises a full-bodied and aromatic cup every time.<br/><br/>Made from high-quality stainless steel, this filter is durable, rust-resistant, and built to last, ensuring a pure brew with no metallic taste. Brew coffee the authentic way with the two-chamber filter system. Water slowly drips through the ground coffee, extracting maximum flavor and aroma for a perfectly balanced cup.<br/><br/>Add your favorite South Indian coffee powder in the top chamber, pour hot water, and let the slow-drip process work its magic. In just minutes, you’ll have the strong, aromatic coffee decoction ready to be mixed with hot milk. This coffee filter is designed to extract the rich, strong flavors that South Indian coffee is known for, giving you a smooth and satisfying brew.<br/><br/>Get the perfect coffee decoction that’s rich, strong, and brimming with flavor. Whether you enjoy your coffee strong or mild, this filter allows you to control the brewing process to suit your taste. It’s great for making traditional kaapi, as well as other strong coffee decoctions. Our traditional coffee filter is reusable, making it an eco-friendly option for daily coffee brewing.<br/><br/>Order today and immerse yourself in the authentic taste of South Indian filter coffee!",
    images: [SouthIndianFilterCoffee],
    price: 399,
    category: "coffeeGear",
  },
  {
    id: "25",
    name: "Stovetop Espresso Maker",
    description:
      "Price: 799 <br/><br/>(Makes 6 cups)<br/><br/>Unleash the Power of Fresh Espresso with our Stovetop Espresso Machine. Brew authentic, rich, and aromatic espresso right in the comfort of your home. Designed for true coffee enthusiasts, this classic espresso maker ensures that every cup you make is full of  flavor and deep aroma. This espresso maker extracts the full flavor of your coffee beans, ensuring a smooth shot. Crafted from durable, high-quality stainless steel, this espresso machine is lightweight yet sturdy, providing excellent heat distribution for consistent brewing. <br/><br/>Just add water to the base, fill the filter with your favorite ground coffee, and place it on your stovetop. In minutes, you'll hear the espresso bubbling and ready to serve!  <br/><br/>Suitable for gas, electric, and ceramic stovetops, it gives you the flexibility to brew at home, on a camping trip, or even at work. Perfect for small kitchens, RVs, or travel, it’s easy to store and lightweight enough to take on the go.<br/><br/>Order now and taste the difference in every cup!",
    images: [Stovetop],
    price: 799,
    category: "coffeeGear",
  },
  {
    id: "26",
    name: "Coffee Warmer",
    description:
      "Our Coffee Warmer is the perfect companion for keeping your brew at the ideal temperature, ensuring every sip is as warm and enjoyable as the first. This compact and stylish warmer helps you savor every drop of your coffee. Crafted from high-quality stainless steel, this coffee warmer is durable, resistant to rust, and designed to distribute heat evenly for consistent warmth. The sleek, lightweight design makes it easy to carry and store. <br/><br/>Order now and keep the warmth in every cup!",
    images: [CoffeeWarmer],
    price: 499,
    category: "coffeeGear",
  },

  {
    id: "08",
    gram: "300 gm",
    name: "Arabica",
    description:
      "Coffee Gable is committed to deliver the most exquisite Arabica coffee beans to your doorstep. Our beans are meticulously sourced from the world's most renowned coffee-producing regions, where the soil, climate, and altitude come together to create coffee beans of unparalleled excellence. <br/> <br/> If you are a coffee connoisseur seeking the finest flavors and aromas in your daily brew, you just found Coffee Gable's premium Arabica coffee beans. Grown at higher elevations in regions with ideal climates and conditions, these beans are celebrated for their smooth, mild flavor, and subtle acidity.To ensure the freshness and flavor of every batch, the beans are roasted in small quantities by our experienced roasters. This means you'll experience the true essence of these beans in every cup you brew. <br/> <br/>  Elevate your coffee experience with our finest Arabica coffee beans. From the Break of dawn to those cherished moments of relaxation, our beans will redefine your coffee rituals.  <br/><br/>Unlike pre-ground or pre-packaged beans sitting on store shelves, our coffee beans are freshly roasted and packed right before delivery. <br/><br/> Order now and savor the freshness in every cup.",
    images: [
      ArabicaCoffee,
      ArabicaCoffee2,
      ArabicaCoffee3,
      ArabicaCoffee4,
      ArabicaCoffee5,
    ],
    price: 499,
    category: "coffeeBeans",
  },
  {
    id: "01",
    gram: "300 gm",
    name: "Robusta",
    description:
      "Coffee Gable is committed to deliver the most exquisite Arabica coffee beans to your doorstep. Our beans are meticulously sourced from the world's most renowned coffee-producing regions, where the soil, climate, and altitude come together to create coffee beans of unparalleled excellence. <br/><br/>The beans are carefully selected, meticulously roasted, and expertly packaged to ensure you experience the full, unadulterated richness of Robusta in every cup. Whether you prefer your coffee brewed as an intense espresso shot or in a smooth, strong French press, our Robusta coffee beans deliver an exceptional taste that suits various brewing methods. Elevate your coffee experience to new heights and savor the distinctive flavors of Robusta. <br/> <br/> 300gm <br/><br/>Unlike pre-ground or pre-packaged beans sitting on store shelves, our coffee beans are freshly roasted and packed right before delivery.   <br/> <br/>Order now and savor the freshness in every cup.",
    images: [Robusta, Robusta2, Robusta3],
    price: 1000,
    category: "coffeeBeans",
  },
  {
    id: "09",
    gram: "300 gm",
    name: "Green coffee Beans",
    description:
      "Coffee Gable is committed to deliver the most exquisite Arabica coffee beans to your doorstep. Our beans are meticulously sourced from the world's most renownedcoffee-producing regions, where the soil, climate, and altitude come together to create coffee beans of unparalleled excellence.<br/><br/>Our premium green coffee beans are the perfect solution for those seeking a higher-quality coffee experience while reaping the incredible health benefits. Green coffeebeans are bursting with antioxidants that help combat free radicals, promoting overall well-being and a youthful glow. Chlorogenic acid in green coffee beans may aidin weight management by supporting healthy metabolism and reducing the absorption of carbohydrates.<br/><br/>Roasting your green coffee beans at home is a rewarding and enjoyable experience. It allows you to customize your coffee's roast level to suit your taste. Whether youprefer a light, medium, or dark roast, you're in control.<br/><br/>Unlock the true potential of coffee with our green beans. Your daily brew will never be the same!<br/><br/>Remember, each sip is an invitation to a healthier and more flavorful lifestyle. Enjoy the ultimate coffee experience with our premium green coffee beans.<br/><br/><br/><br/>Unlike pre-ground or pre-packaged beans sitting on store shelves, our coffee beans are freshly packed right before delivery. <br/><br/>Order now and savor the freshness in every cup.",
    images: [
      GreenCoffeeBeans,
      GreenCoffeeBeans2,
      GreenCoffeeBeans3,
      GreenCoffeeBeans4,
      GreenCoffeeBeans5,
    ],
    price: 399,
    category: "coffeeBeans",
  },
  {
    id: "10",
    name: "Coffee Bean Grinders",
    description: "Coffee Bean Grinders",
    images: [ComingSoon],

    price: 0,
    comingSoon: true,
    category: "coffeeGear",
  },
  {
    id: "11",
    name: "Automatic Coffee machines",
    description: "Manual Grinders",
    images: [ComingSoon],

    price: 0,
    comingSoon: true,
    category: "coffeeGear",
  },

  {
    id: "22",
    name: "Coffee Mugs",
    description: "",
    images: [ComingSoon],
    price: 0,
    category: "coffeeGear",
    comingSoon: true,
  },
  {
    id: "23",
    name: "",
    description: "",
    images: [""],
    price: 0,
    category: "gifting",
    comingSoon: true,
  },
  {
    id: "24",
    name: "Barista Tools Kits",
    description: "Barista Tools Kits",
    images: [ComingSoon],
    price: 0,
    category: "coffeeGear",
    comingSoon: true,
  },
];
